<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>报告管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="3">
          <el-select
            v-model="queryInfo.wafer_id"
            clearable
            placeholder="请选择晶片"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="search()"
            >查询</el-button
          >
        </el-col>
        <el-col :span="17"> &nbsp; </el-col>
        <el-col :span="2">
          <el-button type="success" @click="addDialogVisible = true"
            >二维码制作</el-button
          >
        </el-col>
      </el-row>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-text="数据加载中"
        highlight-current-row
      >
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          prop="wafer_type"
          label="晶片类型"
          fixed
        >
        </el-table-column>
        <el-table-column prop="qrcodeCount" label="二维码个数" >
        </el-table-column>
        <!-- <el-table-column prop="username" label="制作者" >
        </el-table-column> -->
        <el-table-column prop="create_time" label="制作时间">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-view"
              @click="download(scope.row.id)"
            ></el-button>
            <!-- <el-button
              type="danger"
              icon="el-icon-delete"
              @click="_delete(scope.row.id)"
            ></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="120px"
      >
        <el-form-item label="晶片类型" prop="wafer_id">
          <el-select
            v-model="addForm.wafer_id"
            clearable
            placeholder="请选择晶片"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二维码数量" prop="qrcodeCount">
          <el-input v-model="addForm.qrcodeCount"></el-input>
        </el-form-item>
        <!-- <el-form-item label="折扣" prop="discount">
          <el-input v-model="addForm.discount"></el-input>
        </el-form-item> -->
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addDialogVisible: false,
      // 查询参数
      queryInfo: {
        wafer_id: '',
        pageSize: 5,
        currentPage: 1
      },
      options: [],
      loading: true,
      tableData: [],
      total: 0, // 添加的表单数据
      addForm: {
        wafer_id: '',
        qrcodeCount: ''
      },
      // 添加表单的验证规则对象
      addFormRules: {
        wafer_id: [
          { required: true, message: '请选择晶片', trigger: 'blur' }
        ],
        qrcodeCount: [
          { required: true, message: '请输入本次生成的二维码数量', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字', trigger: ['blur', 'change'] }
        ]
      },
      role: ''
    }
  },
  created() {
    this.get_allWafers()
    this.getQRcodeFiles()
    this.role = window.sessionStorage.getItem('role')
  },
  methods: {
    async get_allWafers() {
      await this.$http
        .post(
          'wafer/v1/list_waferType/'
        )
        .then((res) => {
          // console.log(res)
          const options = []
          res.data.data.forEach((element) => {
            const option = {}
            option.value = element.id
            option.label = element.wafer_type
            options.push(option)
          })
          // 更新
          this.options = options
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('查询晶片信息失败！')
        })
    },
    // 搜索
    async getQRcodeFiles() {
      await this.$http
        .post('wafer/v1/list_qrcodefiles/', this.qs.stringify(this.queryInfo))
        .then((res) => {
          // console.log(res)
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.tableData = res.data.data
            this.total = res.data.total
          } else {
            this.$message.error(res.data.msg)
          }
          //
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async add() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        // 可以发起添加用户的网络请求
        await this.$http
          .post('wafer/v1/create_qrcodes/', this.qs.stringify(this.addForm))
          .then((res) => {
            if (res.data.status === 0) {
              this.$message.success(res.data.msg)
              // 隐藏添加用户的对话框
              this.addDialogVisible = false
              // 重新获取用户列表数据
              this.getQRcodeFiles()
            } else {
              this.$message.error(res.data.msg)
            }
          })
          .catch((error) => {
            console.log(error)
            this.$message.error('操作失败！')
          })
      })
    },

    async _delete(id) {
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      // 如果用户确认删除，则返回值为字符串 confirm
      // 如果用户取消了删除，则返回值为字符串 cancel
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      await this.$http
        .delete('user/v1/delete_vipLevel/', {
          params: {
            vipLevel_id: id
          }
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getlevels()
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },

    async download(id) {
      var filePath = ''
      this.tableData.forEach((element) => {
        if (element.id === id) {
          filePath = element.file_path
        }
      })
      var newWindow = window.open()
      newWindow.location.href = filePath
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.getQRcodeFiles()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.queryInfo.currentPage = val
      this.getQRcodeFiles()
    },
    search() {
      // 当前页数重置为1
      this.queryInfo.currentPage = 1
      this.getQRcodeFiles()
    },
    // 监听添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
