<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>发票管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="2">
          <el-select
            v-model="queryInfo.invoice_status"
            clearable
            placeholder="请选择发票状态"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="search()"
            >查询</el-button
          >
        </el-col>
      </el-row>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-text="数据加载中"
        highlight-current-row
      >
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          prop="invoice_header"
          label="发票抬头"
          fixed
        >
        </el-table-column>
        <el-table-column prop="invoice_header" label="发票类型" >
        </el-table-column>
        <el-table-column prop="buyerName" label="名称" >
        </el-table-column>
        <el-table-column prop="buyerTaxNo" label="税号">
        </el-table-column>
        <el-table-column prop="buyerPhone" label="手机号">
        </el-table-column>
        <el-table-column prop="order_id" label="订单编号">
        </el-table-column>
        <el-table-column prop="username" label="申请者姓名">
        </el-table-column>
        <el-table-column prop="email" label="申请者邮箱">
        </el-table-column>
        <el-table-column prop="" label="发票状态">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              v-model="scope.row.invoice_status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="已开具"
              inactive-text="未开具"
              @change="change_invoice_status(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="updateTime" label="更新时间" width="180">
        </el-table-column> -->
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="_delete(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

  </div>
</template>

<script>
export default {
  data() {
    return {
      statusOptions: [
        {
          label: '未开具',
          value: '0'
        },
        {
          label: '已开具',
          value: '1'
        }
      ],
      select: '2',
      // 查询参数
      queryInfo: {
        invoice_status: '',
        pageSize: 5,
        currentPage: 1
      },
      loading: true,
      tableData: [],
      total: 0
    }
  },
  created() {
    this.getInvoices()
  },
  methods: {
    // 搜索
    async getInvoices() {
      await this.$http
        .post('order/v1/search_invoice/', this.qs.stringify(this.queryInfo))
        .then((res) => {
          // console.log(res)
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.tableData = res.data.data
            this.total = res.data.total
          } else {
            this.$message.error(res.data.msg)
          }
          //
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async change_invoice_status(row) {
      await this.$http
        .post(
          'order/v1/update_invoice/',
          this.qs.stringify({
            invoiceId: row.id,
            invoice_status: row.invoice_status === true ? '1' : '0'
          })
        )
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            // 重新获取用户列表数据
            this.getInvoices()
          } else {
            row.invoice_status = !row.invoice_status
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          console.log(error)
          row.invoice_status = !row.invoice_status
          this.$message.error('更新运行状态失败！')
        })
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.getInvoices()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.queryInfo.currentPage = val
      this.getInvoices()
    },
    search() {
      // 当前页数重置为1
      this.queryInfo.currentPage = 1
      this.getInvoices()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
