<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>报告管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="3">
          <el-select
            v-model="queryInfo.owner"
            clearable
            placeholder="请选择用户来源"
          >
            <el-option
              v-for="item in ownerOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-input
            placeholder="搜索：请输入宠物名称"
            class="input-with-select"
            v-model="queryInfo.pet_name"
            clearable
            @change="getWaferResults()"
          >
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="search()"
            >查询</el-button
          >
        </el-col>
      </el-row>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-text="数据加载中"
        highlight-current-row
      >
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          prop="pet_name"
          label="宠物名称"
          fixed
        >
        </el-table-column>
        <el-table-column prop="pet_ownerName" label="主人姓名" >
        </el-table-column>
        <el-table-column prop="pet_age" label="宠物年龄">
        </el-table-column>
        <el-table-column prop="pet_species" label="宠物物种" >
        </el-table-column>
        <el-table-column prop="pet_varieties" label="宠物品种" >
        </el-table-column>
        <el-table-column prop="pet_sex" label="宠物性别" >
        </el-table-column>
        <el-table-column prop="pet_sterilizationStatus" label="是否绝育">
        </el-table-column>
        <el-table-column prop="pet_jiwangshi" label="既往史" >
        </el-table-column>
        <el-table-column prop="samplingPosition" label="采样部位" >
        </el-table-column>
        <el-table-column prop="username" label="诊断者" >
        </el-table-column>
        <el-table-column prop="owner" label="用户来源" >
        </el-table-column>
        <el-table-column prop="create_time" label="报告出具时间">
        </el-table-column>
        <el-table-column fixed="right" label="操作" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-view"
              @click="download(scope.row.id)"
            ></el-button>
            <!-- <el-button
              type="danger"
              icon="el-icon-delete"
              @click="_delete(scope.row.id)"
            ></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

  </div>
</template>

<script>
export default {
  data() {
    return {
      ownerOptions: [
        {
          value: '0',
          label: '由格'
        },
        {
          value: '1',
          label: '兽丘'
        }
      ],
      select: '2',
      // 查询参数
      queryInfo: {
        pet_name: '',
        owner: '',
        pageSize: 5,
        currentPage: 1
      },
      loading: true,
      tableData: [],
      total: 0,
      role: ''
    }
  },
  created() {
    this.getWaferResults()
    this.role = window.sessionStorage.getItem('role')
  },
  methods: {
    // 搜索
    async getWaferResults() {
      await this.$http
        .post('wafer/v1/list_waferResult/', this.qs.stringify(this.queryInfo))
        .then((res) => {
          // console.log(res)
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.tableData = res.data.data
            this.total = res.data.total
          } else {
            this.$message.error(res.data.msg)
          }
          //
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    // 展示编辑报告的对话框
    async download(id) {
      var reportUrl = ''
      this.tableData.forEach((element) => {
        if (element.id === id) {
          reportUrl = element.report_path
        }
      })
      var newWindow = window.open()
      newWindow.location.href = reportUrl
    },

    async _delete(id) {
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      // 如果用户确认删除，则返回值为字符串 confirm
      // 如果用户取消了删除，则返回值为字符串 cancel
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      await this.$http
        .delete('/WaferResultManage/', {
          params: {
            WaferResultId: id
          }
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success('删除成功！')
          } else {
            this.$message.error('操作失败！')
          }
          this.getWaferResults()
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.getWaferResults()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.queryInfo.currentPage = val
      this.getWaferResults()
    },
    search() {
      // 当前页数重置为1
      this.queryInfo.currentPage = 1
      this.getWaferResults()
    },
    // 监听添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
