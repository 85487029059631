<template>
  <el-container class="home_container">
    <!-- 头 -->
    <el-header
      ><div>
        <!-- <img src="../assets/imgs/logo.png" alt="" /> -->
        <span>晶片诊断系统商城后台管理</span>
      </div>
      <el-button type="info" @click="logout">退出</el-button></el-header
    >
    <!-- 主体 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-btn" @click="toggleCollapse">|||</div>
        <el-menu
          :default-active="activePath"
          class="el-menu-vertical-demo"
          :collapse="isCollapse"
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409bff"
          unique-opened
          :collapse-transition="false"
          router
        >
          <!-- 诊断管理 -->
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-guide"></i>
              <span>诊断系统</span>
            </template>
            <el-menu-item index="/qrcode" v-if="role != '3'"
              ><i class="el-icon-video-camera-solid"></i>
              <span>二维码管理</span></el-menu-item
            >
            <el-menu-item index="/wafer" v-if="role != '3'"
              ><i class="el-icon-video-camera-solid"></i>
              <span>晶片管理</span></el-menu-item
            >
            <!-- <el-menu-item index="/hole"
              ><i class="el-icon-video-camera-solid"></i>
              <span>孔位管理</span></el-menu-item
            > -->
            <el-menu-item index="/report"
              ><i class="el-icon-video-camera-solid"></i>
              <span>诊断报告管理</span></el-menu-item
            >
            <el-menu-item index="/qa"
              ><i class="el-icon-video-camera-solid"></i>
              <span>QA管理</span></el-menu-item
            >
          </el-submenu>

          <!-- 商城管理 -->
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-guide"></i>
              <span>商城系统</span>
            </template>

            <el-menu-item index="/goods" v-if="role != '3'"
              ><i class="el-icon-video-camera-solid"></i>
              <span>商品管理</span></el-menu-item
            >
            <el-menu-item index="/order"
              ><i class="el-icon-video-camera-solid"></i>
              <span>订单管理</span></el-menu-item
            >
            <el-menu-item index="/invoice"
              ><i class="el-icon-video-camera-solid"></i>
              <span>发票管理</span></el-menu-item
            >
            <el-menu-item index="/vipLevel"
              ><i class="el-icon-video-camera-solid"></i>
              <span>会员等级管理</span></el-menu-item
            >

            <el-menu-item index="/referralCode" v-if="role != '3'"
              ><i class="el-icon-video-camera-solid"></i>
              <span>活动推荐码管理</span></el-menu-item
            >
            <el-menu-item index="/carouselPicture"
              ><i class="el-icon-video-camera-solid"></i>
              <span>首页轮播图管理</span></el-menu-item
            >

          </el-submenu>

          <!-- 小程序管理 -->
          <!-- <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-guide"></i>
              <span>小程序管理</span>
            </template>
            <el-menu-item index="/carouselPicture"
              ><i class="el-icon-video-camera-solid"></i>
              <span>首页轮播图管理</span></el-menu-item
            > -->
            <!-- <el-menu-item index="/file_upload"
              ><i class="el-icon-video-camera-solid"></i>
              <span>文件上传</span></el-menu-item
            > -->
          <!-- </el-submenu> -->

          <!-- 用户管理 -->
          <el-menu-item index="/users"
            ><i class="el-icon-user"></i> <span>用户管理</span></el-menu-item
          >

          <!-- 我的资料 -->
          <el-menu-item index="/userInfo/"
            ><i class="iconfont icon-yonghuziliao"></i>
            <span style="padding-left: 10px">我的资料</span></el-menu-item
          >
        </el-menu>
      </el-aside>
      <!-- 主体 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      activePath: '',
      role: ''
    }
  },
  created() {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.role = window.sessionStorage.getItem('role')
  },
  methods: {
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
    }
  }
}
</script>

<style lang="less" scoped>
.home_container {
  height: 100%;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;

    // span {
      // margin-left: 15px;
    // }
  }
  img {
    height: 50px;
  }
}
.el-aside {
  background-color: #333744;
  border-right: 0px;
}
.el-main {
  background-color: #eaedf1;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.toggle-btn {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.el-menu {
  border-right: none;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  }
</style>
