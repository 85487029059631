<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input
            placeholder="搜索：请输入订单编号"
            class="input-with-select"
            v-model="queryInfo.order_id"
            clearable
            @change="getOrders()"
          >
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="search()"
            >查询</el-button
          >
        </el-col>
      </el-row>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-text="数据加载中"
        highlight-current-row
      >
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          prop="order_id"
          label="订单编号"
          fixed
        >
        </el-table-column>
        <el-table-column prop="username" label="下单账户">
        </el-table-column>
        <el-table-column prop="address" label="收货地址">
        </el-table-column>
        <el-table-column prop="receiver_name" label="收货人姓名">
        </el-table-column>
        <el-table-column prop="receive_phone" label="收货人电话" width="120px">
        </el-table-column>
        <el-table-column prop="totalAmount" label="商品总价">
        </el-table-column>
        <el-table-column prop="manjian" label="满减">
        </el-table-column>
        <el-table-column prop="realAmount" label="实付款">
        </el-table-column>
        <!-- <el-table-column prop="freightFee" label="订单运费">
        </el-table-column> -->
        <el-table-column prop="statusDesc" label="订单状态">
        </el-table-column>
        <el-table-column prop="order_mark" label="订单备注">
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间">
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间">
        </el-table-column>
        <el-table-column prop="update_username" label="订单更新者">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="edit(scope.row.id)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="_delete(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 修改用户的对话框 -->
    <el-dialog
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="120px"
      >
        <el-form-item label="订单id" prop="id" style="display: none">
          <el-input v-model="editForm.id"></el-input>
        </el-form-item>
        <el-form-item label="收货地址" prop="address">
          <el-input v-model="editForm.address"></el-input>
        </el-form-item>
        <el-form-item label="商品实付款" prop="realAmount">
          <el-input v-model="editForm.realAmount"></el-input>
        </el-form-item>
        <!-- <el-form-item label="订单运费" prop="freightFee">
          <el-input v-model="editForm.freightFee"></el-input>
        </el-form-item> -->
        <el-form-item label="订单备注" prop="order_mark">
          <el-input v-model="editForm.order_mark"></el-input>
        </el-form-item>
        <el-form-item label="订单状态" prop="status">
          <el-select
            v-model="editForm.status"
            clearable
            placeholder="请选择订单状态"
            :disabled= "role == '2' ? true : false"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      statusOptions: [
        {
          label: '待支付',
          value: 5
        },
        {
          label: '待发货',
          value: 10
        },
        {
          label: '待收货',
          value: 40
        },
        {
          label: '已完成',
          value: 50
        },
        {
          label: '已取消',
          value: 60
        }
      ],
      select: '2',
      // 查询参数
      queryInfo: {
        order_id: '',
        pageSize: 5,
        currentPage: 1
      },
      loading: true,
      tableData: [],
      addDialogVisible: false,
      total: 0,

      // 控制修改用户对话框的显示与隐藏
      editDialogVisible: false,
      // 查询到的用户信息对象
      editForm: {
        id: '',
        address: '',
        realAmount: '',
        // freightFee: '',
        order_mark: '',
        status: 0
      },
      // 修改表单的验证规则对象
      editFormRules: {
        order_id: [
          { required: true, message: '请输入订单编号', trigger: 'blur' }
        ]
      },
      role: ''
    }
  },
  created() {
    this.getOrders()
  },
  methods: {

    // 搜索
    async getOrders() {
      await this.$http
        .post('/order/v1/search_order/', this.qs.stringify(this.queryInfo))
        .then((res) => {
          // console.log(res)
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.tableData = res.data.data
            this.total = res.data.total
          } else {
            this.$message.error(res.data.msg)
          }
          //
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    // 展示编辑商品的对话框
    async edit(id) {
      // console.log(id)
      await this.$http
        .get('order/v1/get_order/', {
          params: {
            id: id
          }
        })
        .then((res) => {
          // console.log(res)
          if (res.data.status === 0) {
            this.editForm = res.data.data
            this.editDialogVisible = true
          } else {
            this.$message.error('操作失败！')
          }
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    // 监听修改用户对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    // 修改用户信息并提交
    save() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        // 发起修改用户信息的数据请求
        // console.log(this.editForm)
        await this.$http
          .post('order/v1/update_order/', this.qs.stringify(this.editForm))
          .then((res) => {
            if (res.data.status === 0) {
              // 关闭对话框
              this.editDialogVisible = false
              // 刷新数据列表
              this.getOrders()
              // 提示修改成功
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          })
          .catch((error) => {
            console.log(error)
            this.$message.error('操作失败！')
          })
      })
    },
    async _delete(id) {
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      // 如果用户确认删除，则返回值为字符串 confirm
      // 如果用户取消了删除，则返回值为字符串 cancel
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      await this.$http
        .delete('order/v1/delete_order/', {
          params: {
            id: id
          }
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success('删除成功！')
          } else {
            this.$message.error('操作失败！')
          }
          this.getOrders()
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.getOrders()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.queryInfo.currentPage = val
      this.getOrders()
    },
    search() {
      // 当前页数重置为1
      this.queryInfo.currentPage = 1
      this.getOrders()
    },
    // 监听添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
