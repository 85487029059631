import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

import './assets/css/global.css'
import './assets/fonts/iconfont/iconfont.css'
import 'video.js/dist/video-js.css'
// import 'vue-webrtc-player/packages/src/css/custom-theme.css'
// import vueWebRTCPlayer from 'vue-webrtc-player/packages/index.js'

// import WebRTC from 'vue-webrtc'
// axios
// import axios from 'axios'
// 引入qs
import qs from 'qs'
// axios.defaults.baseURL = 'http://192.168.1.11:8009/'
// // axios 请求拦截器
// axios.interceptors.request.use((config) => {
//   console.log(config)
//   config.headers.Authorization = window.sessionStorage.getItem('token')
//   return config
// })
// Vue.prototype.$http = axios

/** **   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import { Message } from 'element-ui'

// 1. 创建新的axios实例，
const service = axios.create({
  // 公共接口--这里注意后面会讲
  // baseURL: 'http://192.168.18.9:8009/',
  // baseURL: 'http://192.168.3.21:8000/api', // TODO 本地测试使用， 生产环境注释掉
  // baseURL: 'http://127.0.0.1:8000/api', // TODO 本地测试使用， 生产环境注释掉
  // baseURL: 'http://183.196.51.104:38000/', // TODO 生产环境使用
  // baseURL: 'http://47.99.68.206:8000/', // TODO 不带域名的生产环境使用
  baseURL: 'https://program.yg-med.com/api', // TODO 带域名的生产环境使用
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 3 * 60 * 1000
})
// 2.请求拦截器
service.interceptors.request.use(
  (config) => {
    // 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    // config.data = JSON.stringify(config.data) // 数据转化,也可以使用qs转换
    // console.log('请求拦截器中', config)
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded' // 配置请求头
    }
    // 注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
    // const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
    const token = window.sessionStorage.getItem('token')

    if (token) {
      // config.params = { token: token } // 如果要求携带在参数中
      config.headers.Authorization = token // 如果要求携带在请求头中
    }
    return config
  },
  (error) => {
    // console.log('错误') //zys20230504取消打印
    Promise.reject(error)
  }
)

// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.token_status === -1) {
      Message.error('授权过期，请重新登录')
      setTimeout(() => {
        window.location.href = '/'
      }, 3000)
    }
    return response
  },
  (error) => {
    // console.log('error', error)
    /** *** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = '错误请求'
          break
        case 401:
          error.message = '未授权，请重新登录'
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = '请求错误,未找到该资源'
          break
        case 405:
          error.message = '请求方法未允许'
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          error.message = '服务器端出错'
          break
        case 501:
          error.message = '网络未实现'
          break
        case 502:
          error.message = '网络错误'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网络超时'
          break
        case 505:
          error.message = 'http版本不支持该请求'
          break
        default:
          error.message = `连接错误${error.response.status}`
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes('timeout')) {
        Message.error('服务器响应超时，请刷新当前页')
      }
      Message.error('连接服务器失败')
    }
    Message.error(error.message)
    /** *** 处理结束 *****/
    // 如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
  }
)
// 4.导入文件
// export default service
Vue.prototype.$http = service

Vue.prototype.qs = qs
//
Vue.config.productionTip = false
//
// Vue.use(vueWebRTCPlayer)

// Vue.use(WebRTC)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
