import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login'
import Home from '@/components/Home'

import Wafer from '@/components/zhenduan/Wafer'
import Report from '@/components/zhenduan/Report'
import QA from '@/components/zhenduan/QA'
import QRcode from '@/components/zhenduan/QRcode'
import Goods from '@/components/shopping/Goods'
import FileUpload from '@/components/shopping/FileUpload'
import ReferralCode from '@/components/shopping/ReferralCode'
import VipLevel from '@/components/shopping/VipLevel'
import Order from '@/components/shopping/Order'
import Invoice from '@/components/shopping/Invoice'
// import XunjianRecord from '@/components/xunjian/XunjianRecord'
import CarouselPicture from '@/components/miniprogram/CarouselPicture'
// import WebRTC from '@/components/dashboard/WebRTC'
// import CameraSetting from '@/components/xunjian/CameraSetting'
import Users from '@/components/users/Users'
// import DashBoard from '@/components/dashboard/DashBoard'
// import DataVisible from '@/components/dashboard/DataVisible'
// import DataCenter from '@/components/datacenter/DataCenter'
import UserInfo from '@/components/users/UserInfo'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/report', // AFTER LOGIN REDIRECT
    children: [
      { path: '/users', component: Users },
      { path: '/userInfo', component: UserInfo },
      //
      { path: '/wafer', component: Wafer },
      { path: '/report', component: Report },
      { path: '/qrcode', component: QRcode },
      { path: '/qa', component: QA },
      //
      { path: '/goods', component: Goods },
      { path: '/referralCode', component: ReferralCode },
      { path: '/order', component: Order },
      { path: '/invoice', component: Invoice },
      //
      { path: '/vipLevel', component: VipLevel },
      { path: '/carouselPicture', component: CarouselPicture },
      //
      { path: '/file_upload', component: FileUpload }

    ]
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to：将要访问的路径
  // from：从哪个路径跳转而来
  // next：放行
  if (to.path === '/login') {
    return next()
  }
  if (to.path === '/rtc') {
    // sessionStorage.setItem('needRefresh', 'true')
    // next()
  }
  //
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})

export default router
