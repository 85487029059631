<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="3">
          <el-select
            v-model="queryInfo.owner"
            clearable
            placeholder="请选择用户来源"
          >
            <el-option
              v-for="item in ownerOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入姓名"
            v-model="queryInfo.query"
            clearable
            @clear="getUserList"
          >
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getUserList()"
            >查询</el-button
          >
        </el-col>
        <el-col :span="13"> &nbsp; </el-col>
        <el-col :span="2">
          <el-button type="success" @click="addDialogVisible = true"
            >添加用户</el-button
          >
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        highlight-current-row
        border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="姓名" prop="username"></el-table-column>
        <el-table-column label="电话" prop="phoneNumber"></el-table-column>
        <el-table-column label="医院名称" prop="hospital_name"></el-table-column>
        <el-table-column label="医院所在地" prop="hospital_location"></el-table-column>
        <el-table-column label="医院详细地址" prop="hospital_address"></el-table-column>
        <el-table-column label="用户唯一推荐码" prop="referralCode"></el-table-column>
        <el-table-column label="消费积点" prop="ad_points"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="角色" prop="role_name"></el-table-column>
        <el-table-column prop="" label="激活状态" width="150">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              v-model="scope.row.is_active"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="有效"
              inactive-text="无效"
              @change="userStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="" label="用户来源" width="150">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              v-model="scope.row.owner_bool"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="由格"
              inactive-text="兽丘"
              @change="userOwnerChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="edit(scope.row)"
            ></el-button>
            <!-- 删除按钮 -->
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="_delete(scope.row.id)"
            ></el-button>

            <!-- <el-tooltip
              effect="dark"
              content="密码重置"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-refresh-right"
                @click="resetPwd(scope.row.id)"
              ></el-button>
            </el-tooltip> -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <el-dialog
      title="添加用户"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="70px"
      >
        <el-form-item label="姓名" prop="username">
          <el-input v-model="addForm.username" maxlength="4"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phoneNumber">
          <el-input v-model="addForm.phoneNumber"></el-input>
        </el-form-item>
        <el-form-item label="用户密码" prop="password">
          <el-input v-model="addForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="用户邮箱" prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="医院名称" prop="hospital_name">
          <el-input v-model="addForm.hospital_name"></el-input>
        </el-form-item>
        <el-form-item label="医院所在地" prop="hospital_location">
          <el-input v-model="addForm.hospital_location"></el-input>
        </el-form-item>
        <el-form-item label="医院详细地址" prop="hospital_address">
          <el-input v-model="addForm.hospital_address"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-select
            v-model="addForm.role"
            clearable
            placeholder="请选择用户角色"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员状态" prop="is_vip">
          <el-select
            v-model="addForm.is_vip"
            clearable
            placeholder="请选择会员状态"
          >
            <el-option
              v-for="item in vipOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="激活状态" prop="is_active">
          <el-select
            v-model="addForm.is_active"
            clearable
            placeholder="请选择会员状态"
          >
            <el-option
              v-for="item in activeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消费积点" prop="ad_points">
          <el-input v-model="addForm.ad_points"></el-input>
        </el-form-item>
        <el-form-item label="用户来源" prop="owner">
          <el-select
            v-model="addForm.owner"
            clearable
            placeholder="请选择用户来源"
          >
            <el-option
              v-for="item in ownerOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改用户的对话框 -->
    <el-dialog
      title="修改用户"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="70px"
      >
        <el-form-item label="姓名" prop="username">
          <el-input v-model="editForm.username" maxlength="4"></el-input>
        </el-form-item>
        <!-- <el-form-item label="手机号" prop="phoneNumber">
          <el-input v-model="editForm.phoneNumber" maxlength="11"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="用户密码" prop="password">
          <el-input v-model="editForm.password" type="password"></el-input>
        </el-form-item> -->
        <el-form-item label="用户邮箱" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="医院名称" prop="hospital_name">
          <el-input v-model="editForm.hospital_name"></el-input>
        </el-form-item>
        <el-form-item label="医院所在地" prop="hospital_location">
          <el-input v-model="editForm.hospital_location"></el-input>
        </el-form-item>
        <el-form-item label="医院详细地址" prop="hospital_address">
          <el-input v-model="editForm.hospital_address"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-select
            v-model="editForm.role"
            clearable
            placeholder="请选择用户角色"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员状态" prop="is_vip">
          <el-select
            v-model="editForm.is_vip"
            clearable
            placeholder="请选择会员状态"
          >
            <el-option
              v-for="item in vipOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="激活状态" prop="is_active">
          <el-select
            v-model="editForm.is_active"
            clearable
            placeholder="请选择会员状态"
          >
            <el-option
              v-for="item in activeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消费积点" prop="ad_points">
          <el-input v-model="editForm.ad_points"></el-input>
        </el-form-item>
        <el-form-item label="用户来源" prop="owner">
          <el-select
            v-model="editForm.owner"
            clearable
            placeholder="请选择用户来源"
          >
            <el-option
              v-for="item in ownerOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    // // 验证邮箱的规则
    // var checkEmail = (rule, value, cb) => {
    //   // 验证邮箱的正则表达式
    //   const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/

    //   if (regEmail.test(value)) {
    //     // 合法的邮箱
    //     return cb()
    //   }

    //   cb(new Error('请输入合法的邮箱'))
    // }

    // 验证手机号的规则
    // var checkMobile = (rule, value, cb) => {
    //   // 验证手机号的正则表达式
    //   const regMobile =
    //     /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/

    //   if (regMobile.test(value)) {
    //     return cb()
    //   }

    //   cb(new Error('请输入合法的手机号'))
    // }

    return {
      ownerOptions: [
        {
          value: '0',
          label: '由格'
        },
        {
          value: '1',
          label: '兽丘'
        }
      ],
      // 获取用户列表的参数对象
      queryInfo: {
        query: '', // 检索内容
        owner: '', // 所属公司
        pageSize: 5,
        currentPage: 1
      },
      roleOptions: [
        {
          value: '1',
          label: '超级管理员'
        },
        {
          value: '2',
          label: '商城管理员'
        },
        {
          value: '3',
          label: '普通用户'
        }
      ],
      vipOptions: [
        {
          value: '0',
          label: '非正式会员'
        },
        {
          value: '1',
          label: '正式会员'
        }
      ],
      activeOptions: [
        {
          value: '0',
          label: '未激活'
        },
        {
          value: '1',
          label: '激活'
        }
      ],
      tableData: [],
      total: 0,
      // 控制添加用户对话框的显示与隐藏
      addDialogVisible: false,
      // 添加用户的表单数据
      addForm: {
        phoneNumber: '',
        password: '',
        email: '',
        username: '',
        ad_points: '',
        hospital_name: '',
        hospital_location: '',
        hospital_address: '',
        role: '',
        is_vip: '',
        is_active: '',
        owner: ''
      },
      // 添加表单的验证规则对象
      addFormRules: {
        username: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            min: 2,
            max: 4,
            message: '用户名的长度在2~4个字符之间',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
          // { validator: checkEmail, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        hospital_name: [
          { required: true, message: '请输入医院名称', trigger: 'blur' }
        ],
        hospital_address: [
          { required: true, message: '请输入医院详细地址', trigger: 'blur' }
        ],
        is_active: [
          { required: true, message: '请选择激活状态', trigger: 'blur' }
        ],
        is_vip: [
          { required: true, message: '请选择会员状态', trigger: 'blur' }
        ],
        ad_points: [
          { required: true, message: '请输入消费积点', trigger: 'blur' }
        ],
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
          // { validator: checkMobile, trigger: 'blur' } 20240622 境外手机号业务，需要把手机号格式校验注释
        ],
        owner: [
          { required: true, message: '请选择用户来源', trigger: 'blur' }
        ],
        role: [{ required: true, message: '请选择用户角色', trigger: 'blur' }]
      },
      // 控制修改用户对话框的显示与隐藏
      editDialogVisible: false,
      // 查询到的用户信息对象
      editForm: {
        id: '',
        password: '',
        email: '',
        username: '',
        ad_points: '',
        hospital_name: '',
        hospital_location: '',
        hospital_address: '',
        role: '',
        is_vip: '',
        is_active: '',
        owner: ''
      },
      // 修改表单的验证规则对象
      editFormRules: {
        username: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            min: 2,
            max: 4,
            message: '用户名的长度在2~4个字符之间',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
          // { validator: checkEmail, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        hospital_name: [
          { required: true, message: '请输入医院名称', trigger: 'blur' }
        ],
        hospital_address: [
          { required: true, message: '请输入医院详细地址', trigger: 'blur' }
        ],
        is_active: [
          { required: true, message: '请选择激活状态', trigger: 'blur' }
        ],
        is_vip: [
          { required: true, message: '请选择会员状态', trigger: 'blur' }
        ],
        ad_points: [
          { required: true, message: '请输入消费积点', trigger: 'blur' }
        ],
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
          // { validator: checkMobile, trigger: 'blur' } 20240622 境外手机号业务，需要把手机号格式校验注释
        ],
        owner: [
          { required: true, message: '请选择用户来源', trigger: 'blur' }
        ],
        role: [{ required: true, message: '请选择用户角色', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    async getUserList() {
      await this.$http
        .post('/user/v1/list_user/', this.qs.stringify(this.queryInfo))
        .then((res) => {
          // console.log(res)
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.tableData = res.data.data
            this.total = res.data.total
          } else {
            this.$message.error(res.data.msg)
          }
          //
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.queryInfo.currentPage = val
      this.getUserList()
    },
    // 监听 switch 开关状态的改变
    async userStateChanged(row) {
      // console.log(row.status)
      await this.$http
        .post(
          '/user/v1/update_userStatus/',
          this.qs.stringify({
            userId: row.id,
            is_active: row.is_active === true ? '1' : '0'
          })
        )
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            // 重新获取用户列表数据
            this.getUserList()
          } else {
            row.is_active = !row.is_active
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          row.is_active = !row.is_active
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    // 监听 switch 开关状态的改变
    async userOwnerChanged(row) {
      // console.log(row.status)
      await this.$http
        .post(
          '/user/v1/update_userOwner/',
          this.qs.stringify({
            userId: row.id,
            owner: row.owner
          })
        )
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            // 重新获取用户列表数据
            this.getUserList()
          } else {
            row.owner_bool = !row.owner_bool
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          row.owner_bool = !row.owner_bool
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    // 点击按钮，添加新用户
    async add() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        // 可以发起添加用户的网络请求
        await this.$http
          .post('user/v1/addOrUpdateUser/', this.qs.stringify(this.addForm))
          .then((res) => {
            if (res.data.status === 0) {
              this.$message.success(res.data.msg)
              // 隐藏添加用户的对话框
              this.addDialogVisible = false
              // 重新获取用户列表数据
              this.getUserList()
            } else {
              this.$message.error(res.data.msg)
            }
          })
          .catch((error) => {
            console.log(error)
            this.$message.error('操作失败')
          })
      })
    },
    // 展示编辑用户的对话框
    async edit(row) {
      // console.log(id)
      await this.$http
        .get('user/v1/get_user/', {
          params: {
            phoneNumber: row.phoneNumber,
            owner: row.owner
          }
        })
        .then((res) => {
          // console.log(res.data)
          this.editForm = res.data.data
          this.editDialogVisible = true
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('获取信息失败！')
        })
    },
    // 监听修改用户对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    // 修改用户信息并提交
    save() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        // 可以发起添加用户的网络请求
        await this.$http
          .post('user/v1/addOrUpdateUser/', this.qs.stringify(this.editForm))
          .then((res) => {
            if (res.data.status === 0) {
              this.$message.success(res.data.msg)
              // 隐藏添加用户的对话框
              this.editDialogVisible = false
              // 重新获取用户列表数据
              this.getUserList()
            } else {
              this.$message.error(res.data.msg)
            }
          })
          .catch((error) => {
            console.log(error)
            this.$message.error('操作失败')
          })
      })
    },
    async _delete(id) {
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      // 如果用户确认删除，则返回值为字符串 confirm
      // 如果用户取消了删除，则返回值为字符串 cancel
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      await this.$http
        .delete('user/v1/delete_user/', {
          params: {
            user_id: id
          }
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getUserList()
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    // 密码重置
    async resetPwd(id) {
      const confirmResult = await this.$confirm(
        '此操作将重置该用户登密码, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消重置')
      }
      await this.$http
        .get('/resetPwd/', {
          params: {
            userId: id
          }
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success('密码重置成功！')
          } else {
            this.$message.error('密码重置失败！')
          }
          this.getUserList()
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('密码重置失败！')
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
