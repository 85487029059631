<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>会员等级管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="2">
          <el-button type="success" @click="addDialogVisible = true"
            >添加会员等级</el-button
          >
        </el-col>
      </el-row>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-text="数据加载中"
        highlight-current-row
      >
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          prop="level"
          label="会员等级"
          fixed
        >
        </el-table-column>
        <el-table-column prop="ad_points" label="最低积分 ">
        </el-table-column>
        <!-- <el-table-column prop="discount" label="折扣 ">
        </el-table-column> -->
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <!-- <el-button
              type="primary"
              icon="el-icon-edit"
              @click="edit(scope.row.id)"
            ></el-button> -->
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="_delete(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <el-dialog
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="120px"
      >
        <el-form-item label="会员等级" prop="level">
          <el-input v-model="addForm.level"></el-input>
        </el-form-item>
        <el-form-item label="最低消费积点" prop="ad_points">
          <el-input v-model="addForm.ad_points"></el-input>
        </el-form-item>
        <!-- <el-form-item label="折扣" prop="discount">
          <el-input v-model="addForm.discount"></el-input>
        </el-form-item> -->
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      managerOptions: [],
      select: '2',
      // 查询参数
      queryInfo: {
        levelName: '',
        pageSize: 5,
        currentPage: 1
      },
      loading: true,
      tableData: [],
      addDialogVisible: false,
      total: 0,
      // 添加的表单数据
      addForm: {
        level: '',
        ad_points: ''
        // discount: ''
      },
      // 添加表单的验证规则对象
      addFormRules: {
        level: [
          { required: true, message: '请输入会员等级', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字等级', trigger: ['blur', 'change'] }
        ],
        ad_points: [
          { required: true, message: '请输入最低消费积点', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字', trigger: ['blur', 'change'] }
        ]
        // discount: [
        //   { required: true, message: '请输入折扣', trigger: 'blur' },
        //   { pattern: /^(0\.(0[1-9]|[1-9]{1,2}|[1-9]0)$)|^1$|^1\.(0[0]|[0]{1,2})$/, message: '请输入0~1的小数', trigger: ['blur', 'change'] }
        // ]
      }
    }
  },
  created() {
    this.getlevels()
    this.role = window.sessionStorage.getItem('role')
  },
  methods: {
    async getlevels() {
      await this.$http
        .post('user/v1/list_vipLevel/', this.qs.stringify(this.queryInfo))
        .then((res) => {
          // console.log(res)
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.tableData = res.data.data
            this.total = res.data.total
          } else {
            this.$message.error(res.data.msg)
          }
          //
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async add() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        // 可以发起添加用户的网络请求
        await this.$http
          .post('user/v1/add_vipLevel/', this.qs.stringify(this.addForm))
          .then((res) => {
            if (res.data.status === 0) {
              this.$message.success(res.data.msg)
              // 隐藏添加用户的对话框
              this.addDialogVisible = false
              // 重新获取用户列表数据
              this.getlevels()
            } else {
              this.$message.error(res.data.msg)
            }
          })
          .catch((error) => {
            console.log(error)
            this.$message.error('操作失败！')
          })
      })
    },

    async _delete(id) {
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      // 如果用户确认删除，则返回值为字符串 confirm
      // 如果用户取消了删除，则返回值为字符串 cancel
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      await this.$http
        .delete('user/v1/delete_vipLevel/', {
          params: {
            vipLevel_id: id
          }
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getlevels()
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.getlevels()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.queryInfo.currentPage = val
      this.getlevels()
    },
    search() {
      // 当前页数重置为1
      this.queryInfo.currentPage = 1
      this.getlevels()
    },
    // 监听添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
