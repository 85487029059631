<template>
  <div>
   <!-- 这里是添加一个出发对话框的按钮 -->
    <el-row style="margin:20px 10px">
      <el-col align="right">
        <el-button type="primary" @click="addDialogVisible = true">添加轮播图</el-button>
      </el-col>
    </el-row>
     <!-- 添加轮播图 -->
    <el-dialog title="添加轮播图" :visible.sync="addDialogVisible" width="50%">
      <p style="margin:20px 0">上传图片:</p>
      <el-upload
        class="upload-demo"
        ref="fileUpload"
        action="这里就是放oss云储存地址"
        accept=".jpg, .png"
        :on-change="handleChange"
        :on-success="handleSuc"
        :file-list="fileList"
        :auto-upload="false"
        :limit="1"
      >
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <!-- 点击触发上传服务器  -->
        <el-button
          style="margin-left: 10px;"
          size="small"
          type="success"
          @click="submitUpload"
        >上传到服务器</el-button>
          <!-- 给出文件提示 -->
        <div slot="tip" class="el-upload__tip el-icon-warning">只能上传jpg/png文件,且不能超过10mb、文件只能选取一个</div>
        <br />
        <div slot="tip" class="el-upload__tip el-icon-warning">选取文件后应上传服务器</div>
      </el-upload>
        <!-- 上传服务器后图片回显 -->
      <img :src="addBanner.bannerUrl" alt width="200" style="margin:20px auto;display: block;" />
      <div slot="footer" class="dialog-footer">
        <!-- 点击取消后执行一些列清空操作 -->
        <el-button @click="cancel()">取 消</el-button>
          <!-- 最后执行向API添加数据 -->
        <el-button type="primary" @click="submitAdd()">点击添加</el-button>
      </div>
    </el-dialog>
      </div>
</template>

<script>
// 获取添加的API，根据后端API自己写
// import pggwBannerApi from '@/api/pggw/banner'
export default {
  data() {
    return {
      // 控制添加图片对话框
      addDialogVisible: false,
      // 需要向后端传递的对象
      addBanner: {
        bannerUrl: ''
      },
      // 文件列表
      fileList: []
    }
  },
  // 这里采用监听来监听对话框的状态
  watch: {
    addDialogVisible: function () {
      if (this.addDialogVisible === false) {
      // 如果为false，执行取消操作
        this.cancel()
      }
    }
  },
  // 放方法的地方
  methods: {
    // 文件状态改变时的钩子,判断文件大小
    handleChange(files, fileList) {
    // 这些参数是组件自带的
    // 控制上传文件的大小
      if (!(files.size / 1024 / 1024 < 10)) {
        this.$refs.fileUpload.clearFiles()
        this.fileLists = []
        this.$notify.error({
          title: '文件大小错误',
          message: '上传文件大小不能超过 10MB!',
          duration: 2000
        })
      }
    },
    // 上传服务器，点击了上传服务器才会触发下面文件上传成功或失败的钩子
    submitUpload() {
    // 这里是组件自带的submit()手动上传的方法，fileUpload是我们定义的ref
      this.$refs.fileUpload.submit()
    },
    // 文件上传成功时的钩子
    handleSuc(response) {
    // 文件上传成功后要把oss生成的文件路径给到图片的url
    // 这样完成回显图片
      this.addBanner.bannerUrl = response.data.url
      // 上传成功处理
      return this.$notify.success({
        title: '成功',
        message: '文件上传成功',
        duration: 2000
      })
    },
    // 添加轮播图按钮
    submitAdd() {
      // 开关类型布尔值
      var flag = true
      // 判断对象中的值是否为空,用来控制上传前传递参数对象有没有空值(必填项)
      for (var keys in this.addBanner) {
        if (!this.addBanner[keys].trim()) {
          flag = false
          break
        }
      }
      // 关闭所有的消息提醒，解决无限消息弹框问题
      this.$notify.closeAll()
      this.$message.closeAll()
      if (flag) {
        this.$confirm('您确定添加此轮播图', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 这里我们就调用写好的api，向后端传递一个对象参数
            //     pggwBannerApi
            //       .addBanner(this.addBanner)
            //       .then((res) => {
            //         // 添加成功的话，提示一个添加成功
            //         if (res.code == 20000) {
            //           return this.$message({
            //             type: 'success',
            //             message: '添加成功!'
            //           })
            //         }
            //       })
            //       .catch((err) => {
            //         console.log(err)
            //         this.$message({
            //           type: 'error',
            //           message: '添加失败'
            //         })
            //       })
            //   })
            //   .catch(() => {
            //     this.$message({
            //       type: 'info',
            //       message: '已取消添加'
            //     })
          })
      } else {
        return this.$notify({
          title: '警告',
          message: '请填写完整之后提交',
          type: 'warning',
          duration: 1500
        })
      }
    },
    // 点击取消,把对话框重置
    cancel() {
      this.addDialogVisible = false
      // 把对象参数里的值清空
      this.addBanner.bannerUrl = ''
      // 再把文件列表清空
      this.$refs.fileUpload.clearFiles()
    }
  }
}
</script>
