<template>
  <div class="main">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>我的资料</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-descriptions border>
        <el-descriptions-item label="姓名">{{userInfo.username}}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{userInfo.email}}</el-descriptions-item>
        <el-descriptions-item label="医院名称">{{userInfo.hospital_name}}</el-descriptions-item>
        <el-descriptions-item label="医院所在地">{{userInfo.hospital_location}}</el-descriptions-item>
        <el-descriptions-item label="医院详细地址">{{userInfo.hospital_address}}</el-descriptions-item>
        <el-descriptions-item label="用户唯一推荐码">{{userInfo.referralCode}}</el-descriptions-item>
        <el-descriptions-item label="消费积点">{{userInfo.ad_points}}</el-descriptions-item>
        <el-descriptions-item label="角色">{{userInfo.role_name}}</el-descriptions-item>
        <!-- <el-descriptions-item label="激活状态">{{userInfo.is_active}}</el-descriptions-item> -->
      </el-descriptions>
      <!-- <el-button type="primary" style="margin-top:10px;" size="mini" icon="el-icon-key" @click="edit()">修改信息</el-button> -->
    </el-card>

    <!-- 修改用户的对话框 -->
    <el-dialog
      title="修改信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="70px"
      >
        <el-form-item label="用户姓名" prop="username">
          <el-input v-model="editForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="editForm.realName" disabled></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="editForm.password" type="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {
        userName: '',
        realName: '',
        email: '',
        phone: '',
        role: '',
        status: ''
      },
      editDialogVisible: false,
      editForm: {
        userName: '',
        realName: '',
        password: '',
        email: '',
        phone: '',
        role: '',
        status: ''
      },
      editFormRules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 8,
            message: '密码的长度必须大于8个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      const { data: res } = await this.$http.get('user/v1/get_user/', {
        params: {
          phoneNumber: sessionStorage.getItem('phoneNumber'),
          owner: sessionStorage.getItem('owner')
        }
      })
      // console.log(res.userInfo)
      if (res.status !== -1) {
        this.userInfo = res.data
      } else {
        this.$message.error('信息获取失败！')
      }
    },
    // 展示编辑用户的对话框
    async edit() {
      this.getUserInfo()
      this.editForm = this.userInfo
      this.editDialogVisible = true
    },
    // 监听修改用户对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    // 修改用户信息并提交
    save() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        // 发起修改用户信息的数据请求
        // console.log(this.editForm)
        await this.$http
          .post('/updateUserInfo/', this.qs.stringify(this.editForm))
          .then((res) => {
            if (res.data.status === 0) {
              // 关闭对话框
              this.editDialogVisible = false
              // 密码更新后重新登录
              if (res.data.up_pwd === 1) {
                window.sessionStorage.clear()
                this.$router.push('/login')
                // 提示修改成功
                this.$message.success('更新成功，请重新登录！')
              } else {
                // 提示修改成功
                this.$message.success(res.data.msg)
              }
            } else {
              this.$message.error(res.data.msg)
            }
          })
          .catch((error) => {
            console.log(error)
            this.$message.error('操作失败！')
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
