<template>
  <div id="app">
    <div class="login_container">
      <div class="title">晶片诊断系统商城管理系统</div>

      <div class="login_box">
        <!-- 头像 -->
        <!-- <div class="avatar_box">
          <img src="../assets/imgs/logo.png" alt="" />
        </div> -->
        <!-- 表单 -->
        <el-form ref="loginFormRef" class="login_form" :model="form" :rules="loginFormRules">
          <!-- 用户名 -->
          <el-form-item prop="phoneNumber">
            <el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-user"
              v-model="form.phoneNumber"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password">
            <el-input
              placeholder="请输入密码"
              prefix-icon="el-icon-lock"
              v-model="form.password"
              type="password"
            ></el-input>
          </el-form-item>
          <!-- 按钮 -->
          <el-form-item class="btns">
            <el-button type="primary" @click="onSubmit">登录</el-button>
            <el-button type="info" @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        phoneNumber: '',
        password: ''
      },
      loginFormRules: {
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener('keydown', this.keyDown)
  },
  methods: {
    // 点击回车键登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        // this.login() // 定义的登录方法
        this.onSubmit()
      }
    },
    resetForm() {
      this.$refs.loginFormRef.resetFields()
    },
    onSubmit() {
      this.$refs.loginFormRef.validate(async valid => {
        if (valid) {
          const res = await this.$http.post('user/v1/login_system/', this.qs.stringify(this.form))
          // console.log(res)
          if (res.data.status !== 0) {
            return this.$message.error(res.data.msg)
          } else {
            // 保存token到sessionStorage
            window.sessionStorage.setItem('token', res.data.token)
            window.sessionStorage.setItem('phoneNumber', res.data.data.phoneNumber)
            window.sessionStorage.setItem('role', res.data.data.role)
            // 页面跳转
            this.$router.push('/home')
            return this.$message.success('登录成功！')
          }
        } else {
          return this.$message.error('登录失败！')
        }
      })
    }
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  }
}
</script>

<!--scoped 代表只在当前组件生效-->
<style lang="less" scoped>
.title {
  margin: auto;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 25%;
  font-size: 32px;
  font-weight: bold;
  color: #fff;
}
.login_container {
  // background-color: #2b4b6b;
  height: 100%;
  background-image: url('~@/assets/imgs/bg.jpg');
  background-repeat: repeat;
}
.login_box {
  width: 400px;
  height: 300px;
  background-color: #F5F5F5;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: opacity(80%);

  .avatar_box {
    height: 100px;
    width: 200px;
    // border: 1px solid #eee;
    // border-radius: 50%;
    padding: 10px;
    // box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 35%;
    // transform: translate(-50%, -50%);
    // background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
  .login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    .btns {
      float: right;
    }
  }
}
</style>
