<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="2">
          <el-button type="success" @click="addDialogVisible = true"
            >添加轮播图</el-button
          >
        </el-col>
      </el-row>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-text="数据加载中"
        highlight-current-row
      >
        <el-table-column
          prop="picture_index"
          label="轮播顺序"
        >
        </el-table-column>
        <el-table-column prop="picture_path" label="轮播图">
          <template slot-scope="scope">
            <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.picture_path"
            :preview-src-list="scope.row.picture_path_list">
          </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="" label="是否使用">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              v-model="scope.row.is_use"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="使用中"
              inactive-text="未使用"
              @change="useStatusChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="edit(scope.row.id)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="_delete(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加的对话框 -->
    <el-dialog
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="120px"
      >
        <el-form-item label="轮播顺序" prop="picture_index">
          <el-input v-model="addForm.picture_index" placeholder="请填写数字"></el-input>
        </el-form-item>
        <el-form-item label="轮播图">
          <el-upload
            :headers="{ 'Content-Type': 'multipart/form-data'}"
            action="none"
            :show-file-list="true"
            class="file_uploader"
            :on-change="handleChange"
            :auto-upload="false"
            ref="upload"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>

        </el-form-item>
        <el-form-item label="是否使用" prop="is_use">
          <el-select
            v-model="addForm.is_use"
            clearable
            placeholder="请选择是否使用"
          >
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改的对话框 -->
    <el-dialog
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="120px"
      >
        <el-form-item label="轮播顺序" prop="picture_index">
          <el-input v-model="editForm.picture_index" placeholder="请填写数字"></el-input>
        </el-form-item>
        <el-form-item label="轮播图">
          <el-upload
            :headers="{ 'Content-Type': 'multipart/form-data'}"
            action="none"
            :show-file-list="true"
            class="file_uploader"
            :on-change="handleChange"
            :auto-upload="false"
            ref="upload"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>

        </el-form-item>
        <el-form-item label="是否使用" prop="is_use">
          <el-select
            v-model="editForm.is_use"
            clearable
            placeholder="请选择是否使用"
          >
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data() {
    return {
      selectOptions: [
        {
          label: '使用中',
          value: 1
        }, {
          label: '未使用',
          value: 0
        }
      ],
      select: '2',
      // 查询参数
      queryInfo: {
        pageSize: 5,
        currentPage: 1
      },
      loading: true,
      tableData: [],
      addDialogVisible: false,
      total: 0,
      // 添加的表单数据
      addForm: {
        picture_index: '',
        is_use: ''
      },
      // 添加表单的验证规则对象
      addFormRules: {
        picture_index: [
          { required: true, message: '请输入轮播顺序', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字', trigger: ['blur', 'change'] }
        ],
        picture: [
          { required: true, message: '请选择轮播图', trigger: 'blur' }
        ],
        is_use: [
          { required: true, message: '请选择是否使用', trigger: 'blur' }
        ]
      },
      // 控制修改对话框的显示与隐藏
      editDialogVisible: false,
      // 查询到的信息对象
      editForm: {
        id: '',
        picture_index: '',
        is_use: ''
      },
      // 修改表单的验证规则对象
      editFormRules: {
        picture_index: [
          { required: true, message: '请输入轮播顺序', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字', trigger: ['blur', 'change'] }
        ],
        picture: [
          { required: true, message: '请选择轮播图', trigger: 'blur' }
        ],
        is_use: [
          { required: true, message: '请选择是否使用', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getCarouselPictures()
    this.role = window.sessionStorage.getItem('role')
  },
  methods: {
    // 上传文件-----重要,为了拿到 file.raw
    handleChange(file) {
      this.addForm.modelFileName = file.name
      // 这两行代码比较重要---核心代码
      this.mutipartFile = new FormData()
      // this.mutipartFile.append('Content-Type', 'multipart/form-data')
      // modelFile 我们后端需要的参数名字,看你们需求
      this.mutipartFile.append('file', file.raw)
      // this.mutipartFile.append('file', file.raw)
    },

    // 点击确定(创建)
    submitForm() {
      this._submitForm()
    },
    // 节流函数
    async _submitForm() {
      var headers = { 'Content-Type': 'multipart/form-data' }
      const token = window.sessionStorage.getItem('token')
      if (token) {
        headers.Authorization = token
      }

      // 表单中其他输入框的值,需要传递的参数,this.mutipartFile.append('参数名', '值')
      this.mutipartFile.set('picture_index', this.addForm.picture_index)
      this.mutipartFile.set('is_use', this.addForm.is_use)

      axios.post('api/goods/v1/add_update_carouselPicture/', this.mutipartFile, { headers: headers })
        .then(res => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.addDialogVisible = false
            // 重新获取列表数据
            this.getCarouselPictures()
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    // 搜索
    async getCarouselPictures() {
      await this.$http
        .post('goods/v1/list_carouselPicture/', this.qs.stringify(this.queryInfo))
        .then((res) => {
          // console.log(res)
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.tableData = res.data.data
            this.total = res.data.total
          } else {
            this.$message.error(res.data.msg)
          }
          //
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async useStatusChanged(row) {
      await this.$http
        .post(
          'goods/v1/update_useStatus/',
          this.qs.stringify({
            carouselPicture_id: row.id,
            is_use: row.is_use === true ? '1' : '0'
          })
        )
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            // 重新获取列表数据
            this.getCarouselPictures()
          } else {
            row.is_use = !row.is_use
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          console.log(error)
          row.is_use = !row.is_use
          this.$message.error('操作失败！')
        })
    },
    // 展示编辑轮播图的对话框
    async edit(id) {
      await this.$http
        .get('goods/v1/get_carouselPicture/', {
          params: {
            carouselPicture_id: id
          }
        })
        .then((res) => {
          this.editForm = res.data.data
          if (res.data.data.is_use === false) {
            this.editForm.is_use = 0
          } else {
            this.editForm.is_use = 1
          }
          // console.log(0, this.editForm)
          this.editDialogVisible = true
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    // 监听修改对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
      this.$refs.upload.clearFiles()
    },
    // 修改信息并提交
    save() {
      var headers = { 'Content-Type': 'multipart/form-data' }
      const token = window.sessionStorage.getItem('token')
      if (token) {
        headers.Authorization = token
      }
      // 表单中其他输入框的值,需要传递的参数,this.mutipartFile.append('参数名', '值')
      this.mutipartFile.set('carouselPicture_id', this.editForm.id)
      this.mutipartFile.set('picture_index', this.editForm.picture_index)
      this.mutipartFile.set('is_use', this.editForm.is_use)

      axios.post('api/goods/v1/add_update_carouselPicture/', this.mutipartFile, { headers: headers })
        .then(res => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.editDialogVisible = false
            // 重新获取列表数据
            this.getCarouselPictures()
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    async _delete(id) {
      // 弹框询问是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      // 如果确认删除，则返回值为字符串 confirm
      // 如果取消了删除，则返回值为字符串 cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      await this.$http
        .delete('goods/v1/delete_carouselPicture/', {
          params: {
            carouselPicture_id: id
          }
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getCarouselPictures()
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.getCarouselPictures()
    },
    handleCurrentChange(val) {
      this.queryInfo.currentPage = val
      this.getCarouselPictures()
    },
    search() {
      // 当前页数重置为1
      this.queryInfo.currentPage = 1
      this.getCarouselPictures()
    },
    // 监听添加对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
      this.$refs.upload.clearFiles()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
