<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input
            placeholder="搜索：请输入商品名称"
            class="input-with-select"
            v-model="queryInfo.good_name"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getGoods()"
            >查询</el-button
          >
        </el-col>
        <el-col :span="15"> &nbsp; </el-col>
        <el-col :span="2">
          <el-button type="success" @click="addGoods()"
            >添加商品</el-button
          >
        </el-col>
      </el-row>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-text="数据加载中"
        highlight-current-row
      >
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          prop="good_name"
          label="商品名称"
          fixed
        >
        </el-table-column>
        <el-table-column prop="text_description" label="商品文字介绍">
        </el-table-column>
        <el-table-column prop="price" label="商品价格" >
        </el-table-column>
        <el-table-column prop="stock" label="商品库存" >
        </el-table-column>
        <el-table-column prop="sales" label="商品销量" >
        </el-table-column>
        <el-table-column prop="quota_count" label="限购数量" >
        </el-table-column>
        <el-table-column prop="tags" label="促销规则" >
        </el-table-column>
        <el-table-column prop="image_path" label="商品首页图片" >
          <template slot-scope="scope">
            <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.thumb"
            :preview-src-list="scope.row.thumb_list">
          </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="image_path" label="商品轮播图片" >
          <template slot-scope="scope">
            <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.images_one"
            :preview-src-list="scope.row.images">
          </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="image_path" label="商品详情图片" >
          <template slot-scope="scope">
            <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.desc_one"
            :preview-src-list="scope.row.desc">
          </el-image>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="" label="商品视频">
          <template slot-scope="scope">
            <video
              ref="video"
              id="video"
              :src="scope.row.video_description"
              controls
              style="width: 60%; height: 10%"
            ></video>
          </template>
        </el-table-column> -->
        <el-table-column prop="" label="商品状态" width="150">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              v-model="scope.row.good_status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="上架"
              inactive-text="下架"
              @change="goodStatusChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="edit(scope.row.id)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="_delete(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加的对话框 -->
    <el-dialog
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="120px"
      >
        <el-form-item label="商品名称" prop="good_name">
          <el-input v-model="addForm.good_name"></el-input>
        </el-form-item>
        <el-form-item label="商品文字介绍" prop="text_description">
          <el-input v-model="addForm.text_description"></el-input>
        </el-form-item>
        <!-- <el-form-item label="商品视频" prop="video_descriptio">
          <el-input v-model="addForm.video_descriptio"></el-input>
        </el-form-item> -->
        <el-form-item label="商品促销规则" prop="tags">
          <el-input v-model="addForm.tags" placeholder="设定规则：买N赠N（N为整数）"></el-input>
        </el-form-item>
        <el-form-item label="商品价格" prop="price">
          <el-input v-model="addForm.price"></el-input>
        </el-form-item>
        <el-form-item label="商品库存" prop="stock">
          <el-input v-model="addForm.stock"></el-input>
        </el-form-item>
        <el-form-item label="商品销量" prop="sales">
          <el-input v-model="addForm.sales"></el-input>
        </el-form-item>
        <el-form-item label="限购数量" prop="quota_count">
          <el-input v-model="addForm.quota_count"></el-input>
        </el-form-item>
        <!-- <el-form-item label="运费" prop="haulage">
          <el-input v-model="addForm.haulage"></el-input>
        </el-form-item> -->
        <el-form-item label="商品首页图片">
          <el-upload
            :headers="{ 'Content-Type': 'multipart/form-data'}"
            action="none"
            :show-file-list="true"
            class="file_uploader"
            :on-change="handleChange"
            :auto-upload="false"
            ref="upload1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="商品轮播图片">
          <el-upload
            :headers="{ 'Content-Type': 'multipart/form-data'}"
            action="none"
            :show-file-list="true"
            class="file_uploader"
            multiple
            :on-change="handleChange2"
            :auto-upload="false"
            ref="upload2"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="商品详情图片">
          <el-upload
            :headers="{ 'Content-Type': 'multipart/form-data'}"
            action="none"
            :show-file-list="true"
            class="file_uploader"
            multiple
            :on-change="handleChange3"
            :auto-upload="false"
            ref="upload3"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="商品状态" prop="good_status">
          <el-select
            v-model="addForm.good_status"
            clearable
            placeholder="请选择商品状态"
          >
            <el-option
              v-for="item in good_statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改的对话框 -->
    <el-dialog
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="120px"
      >
        <el-form-item label="商品名称" prop="good_name">
          <el-input v-model="editForm.good_name"></el-input>
        </el-form-item>
        <el-form-item label="商品文字介绍" prop="text_description">
          <el-input v-model="editForm.text_description"></el-input>
        </el-form-item>
        <!-- <el-form-item label="商品视频" prop="video_descriptio">
          <el-input v-model="editForm.video_descriptio"></el-input>
        </el-form-item> -->
        <el-form-item label="商品促销规则" prop="tags">
          <el-input v-model="editForm.tags" placeholder="设定规则：买N赠N（N为整数）"></el-input>
        </el-form-item>
        <el-form-item label="商品价格" prop="price">
          <el-input v-model="editForm.price"></el-input>
        </el-form-item>
        <el-form-item label="商品库存" prop="stock">
          <el-input v-model="editForm.stock"></el-input>
        </el-form-item>
        <el-form-item label="商品销量" prop="sales">
          <el-input v-model="editForm.sales"></el-input>
        </el-form-item>
        <el-form-item label="限购数量" prop="quota_count">
          <el-input v-model="editForm.quota_count"></el-input>
        </el-form-item>
        <!-- <el-form-item label="运费" prop="haulage">
          <el-input v-model="editForm.haulage"></el-input>
        </el-form-item> -->
        <el-form-item label="商品首页图片">
          <el-upload
            :headers="{ 'Content-Type': 'multipart/form-data'}"
            action="none"
            :show-file-list="true"
            class="file_uploader"
            :on-change="handleChange"
            :auto-upload="false"
            ref="upload1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="商品轮播图片">
          <el-upload
            :headers="{ 'Content-Type': 'multipart/form-data'}"
            action="none"
            :show-file-list="true"
            class="file_uploader"
            multiple
            :on-change="handleChange2"
            :auto-upload="false"
            ref="upload2"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="商品详情图片">
          <el-upload
            :headers="{ 'Content-Type': 'multipart/form-data'}"
            action="none"
            :show-file-list="true"
            class="file_uploader"
            multiple
            :on-change="handleChange3"
            :auto-upload="false"
            ref="upload3"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="商品状态" prop="good_status">
          <el-select
            v-model="editForm.good_status"
            clearable
            placeholder="请选择商品状态"
          >
            <el-option
              v-for="item in good_statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm2()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data() {
    return {
      good_statusOptions: [
        {
          label: '上架',
          value: '1'
        }, {
          label: '下架',
          value: '0'
        }
      ],
      select: '2',
      // 查询参数
      queryInfo: {
        good_name: '',
        pageSize: 5,
        currentPage: 1
      },
      loading: true,
      tableData: [],
      addDialogVisible: false,
      total: 0,
      // 添加的表单数据
      addForm: {
        good_name: '',
        text_description: '',
        video_descriptio: '',
        tags: '',
        price: '',
        stock: '',
        sales: '',
        quota_count: '',
        // haulage: '',
        good_status: ''
      },
      // 添加表单的验证规则对象
      addFormRules: {
        good_name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        text_description: [
          { required: true, message: '请输入商品文字介绍', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入商品价格', trigger: 'blur' },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入1到2位小数', trigger: ['blur', 'change'] }
        ],
        stock: [
          { required: true, message: '请输入商品库存', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字', trigger: ['blur', 'change'] }
        ],
        sales: [
          { required: true, message: '请输入商品销量', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字', trigger: ['blur', 'change'] }
        ],
        quota_count: [
          { required: true, message: '请输入限购数量', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字', trigger: ['blur', 'change'] }
        ]
        // haulage: [
        //   { required: true, message: '请输入运费', trigger: 'blur' },
        //   { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入1到2位小数', trigger: ['blur', 'change'] }
        // ]
      },
      // 控制修改对话框的显示与隐藏
      editDialogVisible: false,
      mutipartFile: null,
      // 查询到的信息对象
      editForm: {
        goodsId: '',
        good_name: '',
        text_description: '',
        video_descriptio: '',
        tags: '',
        price: '',
        stock: '',
        sales: '',
        quota_count: '',
        // haulage: '',
        good_status: ''
      },
      // 修改表单的验证规则对象
      editFormRules: {
        good_name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        text_description: [
          { required: true, message: '请输入商品文字介绍', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入商品价格', trigger: 'blur' },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入1到2位小数', trigger: ['blur', 'change'] }
        ],
        stock: [
          { required: true, message: '请输入商品库存', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字', trigger: ['blur', 'change'] }
        ],
        sales: [
          { required: true, message: '请输入商品销量', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字', trigger: ['blur', 'change'] }
        ],
        quota_count: [
          { required: true, message: '请输入限购数量', trigger: 'blur' },
          { pattern: /^-?[1-9]\d*$/, message: '请输入数字', trigger: ['blur', 'change'] }
        ]
        // haulage: [
        //   { required: true, message: '请输入运费', trigger: 'blur' },
        //   { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入1到2位小数', trigger: ['blur', 'change'] }
        // ]
      }
    }
  },
  created() {
    this.getGoods()
  },
  methods: {
    // 上传文件-----重要,为了拿到 file.raw
    handleChange(file) {
      // console.log(file)
      this.mutipartFile.append('thumb', file.raw)
    },
    handleChange2(file) {
      // console.log(file)
      this.mutipartFile.append('images', file.raw)
    },
    handleChange3(file) {
      // console.log(file)
      this.mutipartFile.append('desc', file.raw)
    },

    // 点击确定(创建)
    submitForm() {
      this._submitForm('add')
    },
    // 点击确定(创建)
    submitForm2() {
      this._submitForm('edit')
    },
    // 节流函数
    async _submitForm(type) {
      var headers = { 'Content-Type': 'multipart/form-data' }
      const token = window.sessionStorage.getItem('token')
      if (token) {
        headers.Authorization = token
      }

      if (type === 'add') {
        // 表单中其他输入框的值,需要传递的参数,this.mutipartFile.append('参数名', '值')
        this.mutipartFile.set('good_name', this.addForm.good_name)
        this.mutipartFile.set('text_description', this.addForm.text_description)
        this.mutipartFile.set('video_description', this.addForm.video_description)
        this.mutipartFile.set('tags', this.addForm.tags)
        this.mutipartFile.set('price', this.addForm.price)
        this.mutipartFile.set('stock', this.addForm.stock)
        this.mutipartFile.set('sales', this.addForm.sales)
        this.mutipartFile.set('quota_count', this.addForm.quota_count)
        this.mutipartFile.set('haulage', this.addForm.haulage)
        this.mutipartFile.set('good_status', this.addForm.good_status)
      } else {
        // 表单中其他输入框的值,需要传递的参数,this.mutipartFile.append('参数名', '值')
        this.mutipartFile.set('good_name', this.editForm.good_name)
        this.mutipartFile.set('text_description', this.editForm.text_description)
        this.mutipartFile.set('video_description', this.editForm.video_description)
        this.mutipartFile.set('tags', this.editForm.tags)
        this.mutipartFile.set('price', this.editForm.price)
        this.mutipartFile.set('stock', this.editForm.stock)
        this.mutipartFile.set('sales', this.editForm.sales)
        this.mutipartFile.set('quota_count', this.editForm.quota_count)
        this.mutipartFile.set('haulage', this.editForm.haulage)
        this.mutipartFile.set('good_status', this.editForm.good_status)
      }

      // axios.post('http://192.168.3.21:8000/api/goods/v1/add_goodOrUpdate/', this.mutipartFile, { headers: headers }).then(res => {
      axios.post('api/goods/v1/add_goodOrUpdate/', this.mutipartFile, { headers: headers }).then(res => {
        if (res.data.status === 0) {
          this.$message.success(res.data.msg)
          // 隐藏添加的对话框
          this.addDialogVisible = false
          this.editDialogVisible = false
          // 重新获取列表数据
          this.getGoods()
        } else {
          this.$message.error(res.data.msg)
        }
      })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    addGoods() {
      this.addDialogVisible = true
      this.mutipartFile = new FormData()
    },
    // 搜索
    async getGoods() {
      await this.$http
        .post('goods/v1/list_good/', this.qs.stringify(this.queryInfo))
        .then((res) => {
          // console.log(res)
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.tableData = res.data.data
            this.total = res.data.total
          } else {
            this.$message.error(res.data.msg)
          }
          //
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async goodStatusChanged(row) {
      await this.$http
        .post(
          'goods/v1/update_goodStatus/',
          this.qs.stringify({
            good_id: row.id,
            good_status: row.good_status === true ? '1' : '0'
          })
        )
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            // 重新获取列表数据
            this.getGoods()
          } else {
            row.good_status = !row.good_status
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          console.log(error)
          row.good_status = !row.good_status
          this.$message.error('操作失败！')
        })
    },

    // 展示编辑商品的对话框
    async edit(id) {
      // console.log(id)
      await this.$http
        .get('goods/v1/get_good/', {
          params: {
            good_id: id
          }
        })
        .then((res) => {
          //
          this.editForm = res.data.data
          this.editDialogVisible = true
          this.mutipartFile = new FormData()
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    // 监听修改对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
      this.$refs.upload1.clearFiles()
      this.$refs.upload2.clearFiles()
      this.$refs.upload3.clearFiles()
      this.mutipartFile = null
    },
    // 修改信息并提交
    save() {
      this._submitForm('editForm')
    },
    async _delete(id) {
      // 弹框询问是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      // 如果确认删除，则返回值为字符串 confirm
      // 如果取消了删除，则返回值为字符串 cancel
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      await this.$http
        .delete('goods/v1/delete_good/', {
          params: {
            good_id: id
          }
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getGoods()
        })
        .catch((error) => {
          console.log(error)
          this.$message.error('操作失败！')
        })
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.getGoods()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.queryInfo.currentPage = val
      this.getGoods()
    },
    search() {
      // 当前页数重置为1
      this.queryInfo.currentPage = 1
      this.getGoods()
    },
    // 监听添加对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
      this.$refs.upload1.clearFiles()
      this.$refs.upload2.clearFiles()
      this.$refs.upload3.clearFiles()
      this.mutipartFile = null
    }
  }
}
</script>

<style lang="less" scoped>
</style>
